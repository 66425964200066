<template>
    <modal ref="modalRechazarCambio" titulo="Rechazar Cambio de Precio" tamano="modal-md" no-aceptar adicional="Rechazar" @adicional="rechazar">
        <div class="row mx-0 justify-center">
            <div class="col-12 my-3">
                <p class="f-15 text-general pl-3 f-500">
                    Motivo de Rechazo
                </p>
                <el-select v-model="model.id_motivo_rechazo" placeholder="Seleccionar motivo" class="w-100">
                    <el-option
                    v-for="item in motivos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 justify-center">
            <div class="col-12">
                <p class="f-15 text-general pl-3 f-500">
                    Observación
                </p>
                <el-input v-model="model.justificacion" type="textarea" placeholder="Observacion..." :rows="4" maxlength="500" show-word-limit />
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import Motivos from '~/services/pedidos/pedidos-tienda'
import Pedidos from '~/services/pedidos/pedidos-admin'
export default {
    props:{
        idSugerencia:{
            type:Number,
            required:true
        }
    },
    data(){
        return {
            observacion: '',
            value: '',
            motivos: [],
            model:{
                justificacion:'',
                id_motivo_rechazo:null,
                id_sugerencia:null
            }
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
        }),

    },
    methods: {
        toggle(){
            this.model.justificacion = ''
            if(this.motivos.length === 0){
                this.select_motivos()
            }
            this.$refs.modalRechazarCambio.toggle();
        },
        async rechazar(){
            try {

                if(this.model.id_motivo_rechazo === null){
                    this.notificacion('Advertencia','Por favor escoja un motivo para el rechazo de la solicitud.','warning')
                    return
                }

                const {data} = await Pedidos.rechazar_solicitud(this.idSugerencia, this.model)
                this.notificacion('Éxito','Solicitud de cambio de precio Rechazada','success')
                this.$store.dispatch('pedidos/pedidos_admin/pedidos_productos', Number(this.id_pedido))
                this.$refs.modalRechazarCambio.toggle();

            } catch (e){
                this.error_catch(e)
            }
        },
        async select_motivos(){
            try {
                let params = { tipo:22 }
                const {data} = await Motivos.select_motivos(params)
                this.motivos = data.motivos

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style>

</style>
